
import { PaymentService } from "./PaymentService";
import { CookieService } from "./CookieService";
import { FeedbackService } from "./FeedbackService";
import { DownloadService } from "./DownloadService";

const paymentService = new PaymentService();
const cookieService = new CookieService();
const feedbackService = new FeedbackService(cookieService);
const downloadService = new DownloadService();

export {
  paymentService,
  cookieService,
  feedbackService,
  downloadService
}