/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import { StripeProvider } from 'react-stripe-elements';
import { rootConfig } from '../../../../shared/config'

import "./Layout.sass"
import "./layout.css"
import { PurchaseProvider } from "./purchase/hocs/usePurchase";
import SEO from "./seo";

import { globalHistory } from '@reach/router'

class Layout extends React.Component<any, any> {
  constructor(props) {
    super(props);

    this.state = { 
      stripe: null,
      stripeKey: rootConfig.apis.stripe.liveKey
    };
  }

  componentDidMount() {
    // Create Stripe instance in componentDidMount
    // (componentDidMount only fires in browser/DOM environment)

    //@ts-ignore
    this.setState({ stripe: window.Stripe(this.state.stripeKey) });
  }

  scrollToTopOnPageChange () {
    return globalHistory.listen(({ action }) => {
      if (action === 'PUSH') {
        window.scrollTo(0,0)
      }
    })
  }

  componentDidUpdate () {
    this.scrollToTopOnPageChange();
  }

  render() {
    return (
      <>
        <SEO/>
        { typeof window !== 'undefined' ? (
          <StripeProvider apiKey={this.state.stripeKey}>
            <PurchaseProvider location={this.props.location}>
              <main>{this.props.children}</main>
            </PurchaseProvider>
          </StripeProvider>
        ) : '' } 
      </>
    );
  }
}


export default Layout
