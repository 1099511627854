
import Cookie from 'js-cookie'

export class CookieService {
  constructor () {}

  saveHashAsCookie (hashValue) {
    Cookie.set('hash', hashValue);
  }

  getHashFromCookie () {
    return Cookie.get('hash');
  }

  removeCookie () {
    return Cookie.remove('hash');
  }
}