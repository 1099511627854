
const rootConfig = {
  price: 29.99,
  percentageOff: 60,
  originalPrice: 69.99,
  pageCount: 350,
  apis: {
    stripe: {
      liveKey: 'pk_live_6BNjF9sKA3VkgsonhkNSqKbq',
      testKey: 'pk_test_LiKJZunL2XpwxYDZ7I1LpSTM'
    }
  }
}

module.exports = {
  rootConfig
}