
import axios from 'axios'
export class FeedbackService {
  constructor (cookieService) {
    this.feedbackUrl = "https://solidbook.io/api/feedback/submit";
    this.cookieService = cookieService;
  }

  async sendFeedback (textUnderFocus, feedback) {
    await axios({
      method: 'POST',
      url: `${this.feedbackUrl}`,
      data: {
        hash: this.cookieService.getHashFromCookie(),
        textUnderFocus,
        feedback
      }
    });
  }
}