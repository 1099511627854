
import axios from 'axios'

type PurchaseReponse = {
  data: {
    hash: string;
  }
}

export class PaymentService {

  private paymentUrl: string;
  private verificationUrl: string;
  private resendPurchaseUrl: string;
  private totalNumPurchasesUrl: string;
  private currentItem: string;

  constructor () {
    this.paymentUrl = "https://solidbook.io/api/customer/charge";
    this.verificationUrl = 'https://solidbook.io/api/purchases/exists';
    this.resendPurchaseUrl = 'https://solidbook.io/api/email/resend'
    this.totalNumPurchasesUrl = 'https://solidbook.io/api/purchases/total'
    this.currentItem = 'SOLID_BOOK_PREORDER';
  }

  async resendPurchaseEmail (email) {
    await axios({
      method: 'POST',
      url: `${this.resendPurchaseUrl}`,
      data: {
        email
      }
    });
  }

  async getTotalNumberPurchases () {
    const response = await axios({
      method: 'GET',
      url: `${this.totalNumPurchasesUrl}`
    });
    return response.data.numPurchases;
  }

  async checkPaid (hash) {
    try {
      await axios({
        method: 'GET',
        url: `${this.verificationUrl}?hash=${hash}`
      });
      return true;
    } catch (err) {
      console.log(err);
      return false;
    }
  }

  makePayment (email, token, name, copies: number, method?: string): any {
    return axios({
      method: 'POST',
      url: this.paymentUrl,
      data: {
        email: email,
        token: token,
        name: name,
        method: method ? method : '',
        copies: copies,
        item: this.currentItem
      }
    })
  }
}