
import React, { useEffect, useState, createContext } from 'react'
import { validateEmail } from '../../../utils/validateEmail'
import { paymentService } from '../../../services'

type NumBooks = 1 | 3 | 5 | 10 | 15 | 20 | 25 | 50 | 100;

type StripePurchaseProps = { email: string, name: string, stripe: any, copies: number }

interface PurchaseContextProps {
  models: {
    modal: { 
      isOpen: boolean;
      isPurchasing: boolean;
    },
    purchaseQuantity: number;
  },
  operations: { 
    getPricingFor: Function,
    openModal: (copies: number, price: number) => void,
    closeModal: Function,
    makeStripePurchase: (props: StripePurchaseProps) => any,
    makePaypalPurchase: Function
  }
}

const defaultContextValue: PurchaseContextProps = {
  models: { 
    modal: { 
      isOpen: false,
      isPurchasing: false
    },
    purchaseQuantity: 1
  },
  operations: { 
    getPricingFor: () => {},
    openModal: () => {},
    closeModal: () => {},
    makeStripePurchase: () => {},
    makePaypalPurchase: () => {}
  }
}

const PurchaseContext = createContext<PurchaseContextProps>(
  defaultContextValue
);

export function PurchaseProvider ({ children, location }) {

  const [isOpen, setIsOpen] = useState(false);
  const [isPurchasing, setIsPurchasing] = useState(false);
  const [purchaseQuantity, setPurchaseQuantity] = useState(1);
  const [paypalButtons, setPaypalButtons] = useState(undefined);
  

  // useEffect(() => setupPaypal(), []);

  useEffect(() => _setPurchaseQuantity(), [location.hash]);

  const setupPaypal = (copies: number, price: number) => {
    //@ts-ignore
    let buttons = window.paypal.Buttons({
      createOrder: (data, actions) => {
        // Set up the transaction
        return actions.order.create({
          purchase_units: [{
            amount: {
              value: price
            }
          }]
        });
      },
      onApprove: (data, actions) => {
        // Capture the funds from the transaction
        return actions.order.capture().then((details) => {
          const { orderID } = data;

          const email = details.payer.email_address;
          const name = details.payer.name.given_name + details.payer.name.surname;
          const token = orderID;

          makePaypalPurchase({ email, name, token, copies });
        });
      }
    })

    buttons.render('#paypal-button-container');
    setPaypalButtons(buttons)
  }

  const openModal = (copies: number, price: number) => {
    setIsOpen(true);
    setupPaypal(copies, price);
  }

  const closeModal = async () => {
    setIsOpen(false);
    paypalButtons.close();
  }

  const makePaypalPurchase = async ({ email, name, token, copies }) => {
    try {
      setIsPurchasing(true);
      const response = await paymentService.makePayment(email, token, name, copies, 'paypal');
      afterPaymentSuccess(response.data.hash);
    } catch (err) {

      //@ts-ignore
      if (typeof window !== undefined && window.Sentry !== undefined) {
        //@ts-ignore
        window.Sentry.captureException(err);
      }

      // error.response.data.message

      console.log(err);
      alert('Didnt work! Send an email to khalilstemmler@gmail.com.');

    }
    setIsPurchasing(false);
  }

  const makeStripePurchase = async ({ email, name, stripe, copies }) => {

    if (!!email === false) {
      return alert('Howdy, looks like you missed your email!')
    }

    if (validateEmail(email) === false) {
      return alert('Looks like your email isnt quite valid. Want to try that again?')
    }

    if (!!name === false) {
      return alert('Howdy, looks like you missed your name!')
    }

    setIsPurchasing(true);
    
    let { token, error } = await stripe.createToken();

    if (error) {
      // Report some error message
      alert('Invalid card details. Want to try that again?')

    } 

    else {
      // Send to backend
      try {
        const response = await paymentService.makePayment(email, token.id, name, copies, 'stripe');
        afterPaymentSuccess(response.data.hash);
      } catch (err) {

        //@ts-ignore
        if (typeof window !== undefined && window.Sentry !== undefined) {
          //@ts-ignore
          window.Sentry.captureException(err);
        }

        // error.response.data.message

        alert('Didnt work! Try another card.');
        console.log(err);
      }
    }

    setIsPurchasing(false);
  }

  const afterPaymentSuccess = (hash: string) => {
    alert(`Success! When you close this window, you'll be redirected to 
    the web view of the app where you can read the book in it's entirety 
    so far. You'll also be emailed a link where you can read the book in 
    PDF, ePub versions. (Be sure to check your spam folder). Thanks!`);
    
    window.location.href = `https://wiki.solidbook.io/Introduction-872fd41c47f54a61a318c6729ed7c0bd?hash=${hash}`;
  }

  const getPricingFor = (numBooks: NumBooks) : number => {
    switch (numBooks) {
      case 1:
        return 29.99;
      case 3:
        return 80;
      case 5:
        return 125;
      case 10:
        return 270;
      case 15:
        return 400;
      case 20:
        return 515;
      case 25:
        return 675;
      case 50:
        return 1200;
      case 100:
        return 2500;
    }
  }

  const _setPurchaseQuantity = () => {
    const shouldUseTeamAmount = location.hash.indexOf('team_of=') !== -1;
    
    if (shouldUseTeamAmount) {
      let newPurchaseQuantity = Number(location.hash.split('=')[1]);
      setPurchaseQuantity(newPurchaseQuantity);
    }
  }

  const contextValue = {
    ...defaultContextValue,
    models: { 
      modal: {
        isOpen,
        isPurchasing
      },
      purchaseQuantity,
    },
    operations: { 
      getPricingFor,
      openModal,
      closeModal,
      makeStripePurchase,
      makePaypalPurchase
    }
  }

  return (
    <PurchaseContext.Provider value={contextValue}>
      {children}
    </PurchaseContext.Provider>
  )
}

export function usePurchase () {
  const context = React.useContext(PurchaseContext)

  return {
    ...defaultContextValue,
    ...context
  };
}