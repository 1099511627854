
import React from 'react'
import Helmet from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby';

type SiteMetadata = {
  title: string;
  description: string;
  author: string; 
  bookPrice: number;
  priceOff: number;
  siteUrl: string;
  datePublished: string;
};

const GetSiteDetails = graphql`
  query SiteMetadata {
    site {
      siteMetadata {
        title
        description
        author
        bookPrice
        priceOff
        siteUrl
        datePublished
      }
    }
  }
`


function JSONLD (dateModified, datePublished, image, title, url, description) {
  return {
    "@context": "https://schema.org",
    "@type": "Website",
    "mainEntityOfPage": {
      "@type": "WebPage",
      "@id": url
    },
    "creator": {
      "jobTitle": "Software Developer",
      "name": "Khalil Stemmler"
    },
    "headline": title,
    "image": [
      image
     ],
    "datePublished": datePublished,
    "dateModified": dateModified,
    "author": {
      "@type": "Person",
      "name": "Khalil Stemmler"
    },
     "publisher": {
      "@type": "Organization",
      "name": "khalilstemmler.com",
      "logo": {
        "@type": "ImageObject",
        "url": "https://khalilstemmler.com/icons/icon-144x144.png"
      }
    },
    "description": description
  } 
}

/**
 * 
 * This is just here so that we can patch the code.
 */


const SEO = (props) => {
  const data = useStaticQuery(GetSiteDetails);
  const metaData: SiteMetadata = data.site.siteMetadata;

  const title = props.title ? props.title : metaData.title;
  const url = metaData.siteUrl;
  const description = props.description ? props.description : metaData.description;
  const dateModified = new Date().toISOString();
  const image = 'https://solidbook.io/img/banner.png';
  const twitterCard = {
    size: "summary_large_image",
    site: "@stemmlerjs",
    creator: "@stemmlerjs",
  }

  const jsonLDMarkdownRaw = JSONLD(dateModified, dateModified, image, title, url, description)

  return (
    <Helmet
      htmlAttributes={{ lang: `en` }}
      title={title}
      link={[ { rel: 'canonical', href: url } ]}
    >
      <meta name="robots" content="nofollow" />
      <meta name= "image" content={image}/>

      <meta name= "description" content={description}/>

      <meta property= "og:title" content={title}/>
      <meta property= "og:image" content={image}/>
      <meta property= "og:description" content={description}/>
      <meta property= "og:type" content="website" />
      <meta property= "og:url" content={url}/>

      <meta name= "twitter:card" content={twitterCard.size}/>
      <meta name= "twitter:creator" content={twitterCard.creator}/>
      <meta name= "twitter:title" content={title}/>
      <meta name= "twitter:description" content={description}/>
      <meta name= "twitter:image" content={image}/>

      <script type="application/ld+json">
        {JSON.stringify(jsonLDMarkdownRaw)}
      </script>
    </Helmet>
  )
}

export default SEO;
